<script>
import { setComputed } from '@state/helpers'

import { getScrollTop } from '@utils/scroll'

import axios from "axios";

import MixinsMagicColor from "@mixins/MagicColor";

export default {
  mixins: [MixinsMagicColor],
  data() {
    return {
      hostname: `${location.protocol}//${window.location.hostname}`,
      menuList: [
        {
          title: this.$i18n.t('global.NAV_HOME'),
          alias: "home",
          hidden: 0,
          disabled: 0
        },
        {
          title: this.$i18n.t('global.NAV_MENU'),
          alias: "menu",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t('global.NAV_STORE'),
          "alias": "branch",
          active_alias: 'branch',
          children: [],
          hidden: 0,
          disabled: 0,
        },
        {
          "title": this.$i18n.t('global.NAV_FAQ'),
          "alias": "faq",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t('global.NAV_BOOKING'),
          "alias": "booking",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t('global.NAV_GROUP'),
          type: "mainWebsite",
          hidden: 0,
          disabled: 0
        }
      ],
      showHamber: false,
      showMega: false,
      scrollTop: 0,
      window_w: 0,
    }
  },
  computed: {
    isBlackIcon() {
      if(this.$route.name === 'faq') {
        if(this.window_w > 992) {
          if(this.scrollTop < 500) {
            return true
          }else {
            return false
          }
        }else {
          return true
        }
      }else {
        return this.mixinMagicColorLogoColor === '#000'
      }

    },
    ...setComputed,
  },
  mounted() {
    this.getWindowWidth();
    window.addEventListener('resize', this.getWindowWidth);
    document.addEventListener("scroll", this.onScroll, { passive: false });
    this.setBranchMenu();
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth);
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  methods: {
    setLanguage(lang) {
      /* this.$store
        .dispatch('set/setLanguage', {
          language: lang
        }) */
      if(lang !== this.currentLanguage){
        window.location = `${this.$route.path}?language=${lang}`
      }
    },
    formatLanguage(lang) {
      const langList = {
        'en': 'en',
        'zh-TW': '中'
      };

      return langList[lang]
    },
    onClickNavbarOutside() {
      this.showHamber = false
    },
    onScroll() {
      this.scrollTop = this.getScrollTop();

      this.$_mixinMagicColor_checkColor(this.scrollTop)
    },
    setBranchMenu() {
      let alias = "store-extension1"
      const lang = this.$route.params.lang;
      if(lang !== "zh-TW" && lang !== "zh-Hant") {
        alias += "-en"
      }
      axios.post(`/item/search`, {
        category_alias: [alias]
      }).then((response) => {
        this.menuList.forEach((item, $index) => {
          if(item.alias === 'branch') {
            this.menuList[$index].children = response.data.data.items.map(menu=> {
                return {
                  title: menu.title,
                  params: {
                    branchAlias: menu.alias
                  }
                }
            });
          }
        })
      });
    },
    getWindowWidth(){
      this.window_w = document.body.clientWidth
    },
    getScrollTop
  },
  
}
</script>

<template>
  <header :class="[$style.header, { [$style.isScroll]: scrollTop > 70 }]" v-click-outside="onClickNavbarOutside">
    <nav :class="$style.navbar" class="navbar navbar-expand-lg align-items-center justify-content-between">
      <router-link :class="$style.navbarBrand" class="navbar-brand" :to="{ name: 'home' }">
        <transition name="fade" mode="out-in">
          <img v-if="isBlackIcon" :class="$style.logo" src="~@assets/images/logo_black.svg">
          <img v-else :class="$style.logo" src="~@assets/images/logo.svg">
        </transition>
      </router-link>
      <div class="navbar-collapse"
           :class="[$style.navbarCollapse, { [$style.isShow]: showHamber }]"
      >
        <ul :class="$style.navbarNav" class="navbar-nav">
          <template v-for="(menuItem, $index) in menuList">
            <li :class="[$style.navItem, { [$style.isActive]: $route.name === menuItem.active_alias }]" :key="$index" class="nav-item">
              <template v-if="menuItem.type === 'link'">
                <a target="_blank"  :class="$style.navLink" :href="menuItem.link">{{menuItem.title}}</a>
              </template>
              <template v-else-if="menuItem.type === 'mainWebsite'">
                <a target="_blank"  :class="$style.navLink" :href="currentWebsite.main">{{menuItem.title}}</a>
              </template>
              <template v-else-if="menuItem.children">
                <span
                  :class="[$style.navLink, $style.hasChildren, { [$style.isActive]: $route.name === menuItem.active_alias }]"
                  class="nav-link"
                >
                  {{menuItem.title}}
                </span>
                <ul :class="$style.subNavbarNav" class="navbar-nav">
                  <li v-for="(subMenuItem, $index2) in menuItem.children"
                      :key="$index2"
                      :class="$style.subNavItem"
                      class="nav-item">
                    <router-link
                      :class="$style.navLink"
                      class="nav-link"
                      exact
                      :to="{ name: menuItem.alias, params: subMenuItem.params }"
                    >
                      {{subMenuItem.title}}
                    </router-link>
                  </li>
                </ul>
              </template>
              <template v-else>
                <router-link
                  :class="$style.navLink"
                  class="nav-link"
                  exact
                  :to="{ name: menuItem.alias, params: menuItem.params }"
                  :active-class="$style.isActive"
                >
                  {{menuItem.title}}
                </router-link>
              </template>
            </li>
          </template>
        </ul>
      </div>
      <div :class="$style.navRight" class="d-flex align-items-center justify-content-end">
        <!--<button class="btn btn-outline-light isCircle my-2 my-sm-0" type="submit">{{formatLanguage(currentLanguage)}}</button>-->
        <button @click="showHamber = !showHamber" :class="$style.navbarToggle">
          <img v-if="showHamber" :class="$style.close" src="@assets/images/icon/close.svg">
          <template v-else>
            <img v-if="isBlackIcon" src="@assets/images/icon/hamburger_black.svg">
            <img v-else src="@assets/images/icon/hamburger.svg">
          </template>

        </button>
      </div>
    </nav>
  </header>
</template>
<style lang="scss" module>

  .header {
    // height: 130px;
    z-index: 4;
    position: relative;
    &.isScroll {
      /* height: 92px;
      @include touch {
        height: 70px;
      }
      .navbarBrand, .navRight {
        height: 44px;
        .logo {
          margin-top: -11px;
          height: 55px;
          @include touch {
            height: 42px;
            margin-top: 0;
          }
        }
      }
      .megaDropdown {
        & > .megaMenu {
          top: 92px;
        }
      } */
    }
  }
  .navbar {
    padding: 14px 44px;
    // background: rgba(#000, 0.75);
    pointer-events: none;
    position: fixed;
    width: 100vw;
    @include touch {
      padding: 0.5rem 1rem;
    }
  }
  .navbarBrand, .navRight {
    pointer-events: auto;
    position: relative;
    z-index: 2;
    width: 102px;
    height: 102px;
    transition: height 0.3s;
    @include touch {
      height: 54px !important;
    }
    .logo {
      transition: height 0.3s;
      height: 94px;
      @include touch {
        height: 44px;
      }
    }
  }
  .navRight {
    width: 102px;
    height: 102px;
    @include touch {
      height: 54px !important;
    }
  }
  .navbarToggle {
    appearance: none;
    background: transparent;
    border: 0px solid transparent;
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 30px;
    padding: 0;
    width: 35px;
    text-align: center;
    line-height: 5px;
    .close {
      color: #000;
      width: 16px;
      height: 16px;
      @include touch {
        width: 25px;
        height: 25px;
      }
    }
    &:focus {
      outline: none;
    }
  }
  .navbarCollapse {
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 100vw;
    right: 0;
    background: #fff;
    height: 100vh;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1;
    overflow-y: scroll;
    overscroll-behavior: contain;
    width: 260px;
    text-align: center;

    @include touch {
      align-items: center;
      width: 100%;
      display: flex !important;
      justify-content: center;
    }
    .navbarNav {
      flex-direction: column;
      width: fit-content;
      text-align: left;
      padding-left: 78px;
      @include touch {
        width: 100%;
        padding-left: 0;
        text-align: center;
      }
    }
    &.isShow {
      pointer-events: auto;
      opacity: 1;
      transform: translateX(0);
      padding-top: calc((100vh - 320px) / 2);
      display: block !important;
      @include touch {
        padding-top: 0;
        display: flex !important;
      }
    }
  }
  .navItem {
    padding-left: 0;
    padding-right: 0;
    &:hover {
      > .navLink:not(.isActive) {
        color: #aaa;
        text-decoration: none;
        &:active {
          opacity: 0.5;
        }
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: #e85400;
          border-radius: 100%;
          position: absolute;
          left: -1.2rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    > .subNavbarNav {
      display: none;
      flex-direction: column;
      @include touch {
        background: rgba(#aaa, 0.17);
      }
      & > .subNavItem {
        position: relative;
        right: -18px;
        @include touch {
          right: 0px;
          .navLink {
            font-size: 14px;
            letter-spacing: 2.52px;
          }
        }
        &:hover {
          .navLink:not(.isActive) {
            color: #aaa;
          }
        }
      }
    }
    &:hover, &.isActive {
      & > .subNavbarNav {
        display: block;
      }
      /*.navLink {
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: #fff;
          border-radius: 100%;
          position: absolute;
          left: 1rem;
          top: 50%;
          margin-top: -4px;
        }
      }*/
    }

  }
  .navLink {
    // padding-right: 2.5rem !important;
    // padding-left: 2.5rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: #000;
    position: relative;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 9.5px auto;
    display: inline-block;
    font-size: 14px;
    width: fit-content;
    // white-space: nowrap;
    @include touch {
      display: block !important;
      font-size: 18px;
      line-height: 60px;
      letter-spacing: 3.24px;
      text-align: center;
      padding: 0;
      width: fit-content;
      margin: auto;
    }
    &.hasChildren {
      position: relative;
      &:before {
        content: '+';
        position: absolute;
        left: -1.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.isActive {
      color: #aaa;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #e85400;
        border-radius: 100%;
        position: absolute;
        left: -1.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
</style>
