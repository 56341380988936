export const DEFAULT_LANGUAGE = 'zh-TW'; // 預設語言
export const FALLBACK_LANGUAGE = 'zh-TW'; // 當某個語言不存在時，提供一個默認的語言去處理

export const SUPPORTED_LANGUAGES = [
  {
     code: 'en',
     alias: 'en',
     title: 'English'
   },
   {
     code: 'en-US',
     alias: 'en',
     title: 'English'
   },
  {
    code: 'zh-Hant',
    alias: 'zh-TW',
    title: '繁體中文'
  },
  {
    code: 'zh-TW',
    alias: 'zh-TW',
    title: '繁體中文'
  }
];

