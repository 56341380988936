import axios from "axios";
axios.defaults.baseURL = '/api';

export default [
  {
    path: '/:lang',
    name: 'home',
    component: () => lazyLoadView(import('@views/home')),
  },
  {
    path: '/:lang/menu',
    name: 'menu',
    component: () => lazyLoadView(import('@views/menu')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        // routeTo.params.menuModule = [
        //   {
        //     "title": "套餐組合",
        //     "alias": "kfzjrsdcgnynets34a1i",
        //     "ordering": 22,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": null,
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "zh-TW",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "套餐組合",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/course-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "套餐組合",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/course-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "繁體中文"
        //   },
        //   {
        //     "title": "陸饗",
        //     "alias": "b0s1ixtnhna0qjagz3qg",
        //     "ordering": 23,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "陸饗",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/turf-combo-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "陸饗",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/turf-combo-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "海饗",
        //     "alias": "sccxnkypwghepdddevwx",
        //     "ordering": 24,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "海饗",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/surf-combo-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "海饗",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/surf-combo-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "絕品",
        //     "alias": "0njf1q7xeezxzl3dqrzl",
        //     "ordering": 25,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "絕品",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/premium-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "絕品",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/premium-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "生魚片",
        //     "alias": "tujjzbmumqpzw9930rdf",
        //     "ordering": 26,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "生魚片",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/sashimi-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "生魚片",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/sashimi-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "肉品,海鮮",
        //     "alias": "v0o1r3n4hmcfylcfi8q3",
        //     "ordering": 27,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "肉品",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/meat.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "海鮮",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/seafood.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "手工丸,蔬菜,日本小品",
        //     "alias": "ogmch0ej49pmr41ojxuu",
        //     "ordering": 28,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "手工丸,蔬菜",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/handmade-vegetable.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "日本小品",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/japan-selection.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "日本酒",
        //     "alias": "0tfpcgbe9vvtzn16phn6",
        //     "ordering": 29,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "日本酒",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/sake1-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "日本酒",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/sake1-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "日本酒",
        //     "alias": "aaoy58giqtkg1g5qk2nr",
        //     "ordering": 30,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "日本酒",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/sake2-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "日本酒",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/sake2-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "洋酒",
        //     "alias": "ggls5yoxx9qvtqfg9kew",
        //     "ordering": 31,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "洋酒",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/wine-1.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "洋酒",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/wine-2.jpg",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   },
        //   {
        //     "title": "無酒精飲品",
        //     "alias": "tmhlspbvl95aqhogikby",
        //     "ordering": 32,
        //     "introimage": null,
        //     "introtext": null,
        //     "image": null,
        //     "description": "",
        //     "video": null,
        //     "link": null,
        //     "hits": 0,
        //     "featured": 0,
        //     "featured_ordering": null,
        //     "language": "*",
        //     "metadesc": null,
        //     "metakeywords": null,
        //     "extrafields": {
        //       "menu_title1": {
        //         "title": "菜單名稱1 (左側)",
        //         "alias": "menu_title1",
        //         "type": "text",
        //         "required": 0,
        //         "value": "無酒精飲品",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image1": {
        //         "title": "菜單圖片1 (左側)",
        //         "alias": "menu_image1",
        //         "type": "image",
        //         "required": 0,
        //         "value": "/storage/media/orangeshabushabu/menu/no-alcohol.jpg",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_title2": {
        //         "title": "菜單名稱2 (右側)",
        //         "alias": "menu_title2",
        //         "type": "text",
        //         "required": 0,
        //         "value": "",
        //         "description": null,
        //         "params": []
        //       },
        //       "menu_image2": {
        //         "title": "菜單圖片2 (右側)",
        //         "alias": "menu_image2",
        //         "type": "image",
        //         "required": 0,
        //         "value": "",
        //         "description": null,
        //         "params": []
        //       }
        //     },
        //     "created_at": "2019-11-06 14:15:37",
        //     "publish_up": "2019-11-06 14:15:37",
        //     "publish_down": null,
        //     "creator": "橘色集團 管理者1",
        //     "tags": [],
        //     "category_title": "橘色涮涮屋菜單",
        //     "category_alias": "orangeshabushabu-menu",
        //     "language_title": "All"
        //   }
        // ]
        try {
          let alias = "extension1-menu"
          const lang = routeTo.params.lang;
          if(lang !== "zh-TW" && lang !== "zh-Hant") {
            alias += "-en"
          }
          await axios.get('/module/'+alias).then((response) => {
            routeTo.params.menuModule =  response.data.data.items.items.data;
          });
        } catch (error) {
          next({ name: '404' })
        }
        next()
      },
    },
    props: (route) => ({
      menuModule: route.params.menuModule || {},
    }),
  },
  {
    path: '/:lang/branch/:branchAlias',
    name: 'branch',
    component: () => lazyLoadView(import('@views/branch')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          /* const currentBranchItem = async () => {
            return await axios.get(`/item/${routeTo.params.branchAlias}`).then((response) => {
              return response.data.data.items
            });
          };

          await currentBranchItem()
            .then((branchItem) => {
              routeTo.params.branchItem =  branchItem;
              axios.post(`/media/folderFiles`, {
                  dir: branchItem.extrafields.store_media.value
                }).then((response) => {
                  if(response.data.data.items.length > 0){
                    routeTo.params.banner = response.data.data.items ;
                  }
                });
            }); */
          let alias = "store-extension1"
          const lang = routeTo.params.lang;
          if(lang !== "zh-TW" && lang !== "zh-Hant") {
            alias += "-en"
          }

          await axios.post(`/item/search`, {
            category_alias: [alias]
          }).then((response) => {
            routeTo.params.branchList =  response.data.data.items;
          });

          await axios.get(`/item/${routeTo.params.branchAlias}`).then((response) => {
            routeTo.params.branchItem = response.data.data.items;
          });
          // routeTo.params.banner = [
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide1.jpg",
          //       "type": "image/jpeg"
          //   },
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide2.jpg",
          //       "type": "image/jpeg"
          //   },
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide3.jpg",
          //       "type": "image/jpeg"
          //   },
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide4.jpg",
          //       "type": "image/jpeg"
          //   }
          // ]
          await axios.post(`/media/folderFiles`, {
            dir: routeTo.params.branchItem.extrafields.store_media.value
          }).then((response) => {
            if(response.data.data.items.length > 0){
              routeTo.params.banner = response.data.data.items ;
            }
          });

          /* await axios.get(`/item/${routeTo.params.branchAlias}`).then((response) => {
            routeTo.params.branchItem =  response.data.data.items;
          }); */

        } catch (error) {
          next({ name: '404' })
        }
        next()
      },
    },
    props: (route) => ({
      branchList: route.params.branchList || [],
      branchItem: route.params.branchItem || {},
      banner: route.params.banner,
    }),
  },
  {
    path: '/:lang/faq',
    name: 'faq',
    component: () => lazyLoadView(import('@views/faq')),
  },
  {
    path: '/:lang/booking',
    name: 'booking',
    component: () => lazyLoadView(import('@views/booking')),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    props: true,
  },
  {
    path: '*',
    redirect: '/404',
  },
]


function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@views/_loading').default,
    delay: 400,
    error: require('@views/_timeout').default,
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
