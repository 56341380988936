export function getScrollTop() {
  let scrollTop = 0;

  if (typeof window.pageYOffset !== 'undefined') {
    scrollTop = window.pageYOffset;
  } else if (typeof document.compatMode !== 'undefined' && document.compatMode !== 'BackCompat') {
    scrollTop = document.documentElement.scrollTop;
  } else if (typeof document.body !== 'undefined') {
    scrollTop = document.body.scrollTop;
  }

  return scrollTop;
}

export function scrollTo(value, offset = 0, animate = false) {
  const sectionTop = typeof value === 'string'
    ? document.querySelector(`.${value}`).offsetTop + offset
    : value + offset;


  if (animate === 'linear') {
    const start = getScrollTop();
    const change = sectionTop - start;
    let currentTime = 0;
    const increment = 10;
    const duration = 600;

    var animateScroll = function(){
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if(currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }else {
    window.scrollTo(0, sectionTop);
  }
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};
