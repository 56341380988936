export default {
  data() {
    return {
      sectionList: [],
      mixinMagicColorLogoColor: '#fff',
      sectionMin: 0,
      sectionMax: 0,
    }
  },
  mounted() {
    this.$_mixinMagicColor_setSectionList();
    this.$_mixinMagicColor_setColor(0);
  },
  methods: {
    $_mixinMagicColor_checkColor(scrollTop) {
      // 例外判斷 首頁的logo有時候滑到底不會變色
      if(this.$route.name === 'home') {
        const scrollEndOffset = scrollTop + this.$_mixinMagicColor_getWindowHeight();
        const scrollHeight = this.getScrollHeight();

        if(this.$_mixinMagicColor_getWindowWidth() > 991) {
          if(scrollEndOffset === scrollHeight){
            this.mixinMagicColorLogoColor = '#fff';
            return true
          }else if (scrollEndOffset < scrollHeight && scrollEndOffset > scrollHeight - 500){
            this.mixinMagicColorLogoColor = '#000';
            return true
          }
        }
      }

      if((scrollTop < this.sectionMin || scrollTop >= this.sectionMax) || scrollTop === 0 ) {
        this.$_mixinMagicColor_setColor(scrollTop);
      }
    },
    $_mixinMagicColor_setSectionList() {
      const sectionList = [];

      document.querySelectorAll('.section').forEach(item => {
        sectionList.push({
          el: item,
          logoColor: item.getAttribute('data-section-logo-color'),
          offsetMin: item.offsetTop,
          offsetMax: item.offsetTop + item.offsetHeight,
        })
      });

      this.sectionList = sectionList;
    },
    $_mixinMagicColor_setColor(scrollTop) {
      let section = null;

      this.sectionList.some((item, index) => {
        if(scrollTop >= item.offsetMin && scrollTop < item.offsetMax) {
          section = this.sectionList[index];
          return true;
        }
      });

      // console.log(this.sectionList)
      // console.log(thisSection)
      if(section) {
        this.mixinMagicColorLogoColor = section.logoColor;
        this.sectionMin = section.el.offsetTop;
        this.sectionMax = section.el.offsetTop + section.el.offsetHeight;
      }
    },
    $_mixinMagicColor_getWindowHeight(){
      let windowHeight = 0;
      if(document.compatMode === "CSS1Compat"){
        windowHeight = document.documentElement.clientHeight;
      }else{
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    },
    $_mixinMagicColor_getWindowWidth(){
      let windowWidth = 0;
      if(document.compatMode === "CSS1Compat"){
        windowWidth = document.documentElement.clientWidth;
      }else{
        windowWidth = document.body.clientWidth;
      }
      return windowWidth;
    },
    getScrollHeight(){
      let scrollHeight = 0;
      let bodyScrollHeight = 0;
      let documentScrollHeight = 0;

      if(document.body){
        bodyScrollHeight = document.body.scrollHeight;
      }
      if(document.documentElement){
        documentScrollHeight = document.documentElement.scrollHeight;
      }
      scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight ;
      return scrollHeight;
    }
  },
};
