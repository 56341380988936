<script>
import { setComputed, videoComputed } from '@state/helpers'

export default {
  computed: {
    ...setComputed,
    ...videoComputed,
  },
}
</script>
<template>
  <footer :class="[$style.footer, { [$style.isHome]: $route.name === 'home' }]">
    <div
      class="d-flex flex-column flex-lg-row justify-content-between align-items-center align-items-lg-end text-center text-md-left">
      <!--<div :class="$style.textBox">
        <a target="_blank" href="tel:+886 2-2711-4636">+886 2-2711-4636</a>
        <br>
        <a target="_blank" href="mailto:orangeshabu@gmail.com">orangeshabu@gmail.com</a>
        <br>
        <a target="_blank" :href="currentWebsite.main">橘色餐飲集團</a>
        <br class="d-block d-lg-none">
        <span class="d-none d-md-inline-block">｜</span>
        <a target="_blank" href="https://goo.gl/maps/xChmSxpKYwCRLcGA6">台北市大安區仁愛路四段37號5樓之A</a>
      </div>-->
      <div :class="$style.textBox">
        <a target="_blank" :href="currentWebsite.main">{{$t('global.FOOTER_GROUP_TITLE')}}</a>
        <br>
        <!-- <a target="_blank" href="tel:+886 2-2711-4636">+886 2-2711-4636</a>
        <br class="d-block d-lg-none">
        <span class="d-none d-md-inline-block">｜</span> -->
        <a target="_blank" href="mailto:orangeshabu@gmail.com">orangeshabu@gmail.com</a>
        <!--<br>
        <a target="_blank" href="https://goo.gl/maps/xChmSxpKYwCRLcGA6">台北市大安區仁愛路四段37號5樓之A</a>-->
      </div>
      <div>
        <div :class="$style.socialList">
          <div :class="$style.socialItem">
            <a target="_blank" href="https://lihi1.com/aXnnd">
              <img src="@assets/images/icon/facebook_white.png">
            </a>
          </div>
          <div :class="$style.socialItem">
            <a target="_blank" href="https://www.instagram.com/extension1byorangeshabu/">
              <img src="@assets/images/icon/instagram_white.png">
            </a>
          </div>
        </div>
        <div :class="$style.copyRight">
          Copyright © 2019 Orange Co. All rights reserved
        </div>
      </div>

    </div>
  </footer>
</template>
<style lang="scss" module>
  .footer {
    font-family: "Noto Sans TC", sans-serif;
    font-size: 12px;
    line-height: 30px;
    background: #1d1d1d;
    color: #fff;
    padding: 26px 44px 22px;
    z-index: 0;
    position: relative;
    line-height: 2;
    font-weight: 300;
    z-index: 1;
    letter-spacing: 0.96px;
    &.isHome {

    }
    .textBox {
      @include touch {
        margin-bottom: 30px;
      }
    }
    .copyRight {

    }
    .socialList {
      display: flex;
      // padding: 0 0 23px;
      justify-content: flex-end;
      @include touch {
        justify-content: center;
      }
      .socialItem {
        opacity: 0.6;
        margin-left: 15px;
      }
    }
    @include touch {
      padding: 40px 15px;
      line-height: 30px;
      font-weight: 400;
    }
    a {
      color: #fff;
      &:hover {
        color: #e85400;
        text-decoration: none;
      }
    }
  }
</style>

