const isMobile = document.body.clientWidth <= 1024;

const setVideoImages = (type, total, repeatEnd = 0) => {
  let images = [];

  for (let i = 0; i < total; i++) {
    images.push(require(`@assets/images/video/${type}/${i}.jpg`));
  }

  for (let i = 0; i < repeatEnd; i++) {
    images.push(require(`@assets/images/video/${type}/${total- 1}.jpg`));
  }
  return images;
};

const setVideoDuration = (total) => {
  return total * 40;
};

export const state = {
  cachedVideo: {
    videoA: {
      images: isMobile ? setVideoImages('mobile', 153, 50) : setVideoImages('a', 153),
      duration: isMobile ? setVideoDuration(203) : setVideoDuration(153)
    },
    videoB: {
      images: setVideoImages('b', 72),
      duration: setVideoDuration(72)
    },
    videoC: {
      images: setVideoImages('c', 72),
      duration: setVideoDuration(72)
    },
    videoD: {
      images: setVideoImages('d', 293),
      duration: setVideoDuration(293)
    },
    // 5
    videoE: {
      images: setVideoImages('e', 72),
      duration: setVideoDuration(72)
    },
    videoF: {
      images: setVideoImages('f', 72),
      duration: setVideoDuration(72)
    },
    videoG: {
      images: setVideoImages('g', 72),
      duration: setVideoDuration(72)
    },
    videoH: {
      images: setVideoImages('h', 70),
      duration: setVideoDuration(70)
    },
    videoI: {
      images: setVideoImages('i', 72),
      duration: setVideoDuration(72)
    },
    // 10
    videoJ: {
      images: setVideoImages('j', 72),
      duration: setVideoDuration(72)
    },
    videoK: {
      images: setVideoImages('k', 72),
      duration: setVideoDuration(72)
    },
    videoL: {
      images: setVideoImages('l', 72, 75),
      duration: setVideoDuration(147)
    },
  },
  cachedPreloadDone: false
};

export const getters = {};

export const mutations = {
  CACHE_PRELOAD_DONE(state, currentPreloadDone) {
    state.cachedPreloadDone = currentPreloadDone
  },
};

export const actions = {
  setPreloadDone({ commit }, preloadDone) {
    commit('CACHE_PRELOAD_DONE', preloadDone);
  },
};

